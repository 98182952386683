export const msgDictionary = [
    "For quick draft notes",
    "Work offline",
    "Private notes within your browser",
    "Cogito ergo sum!",
    "Nosce te ipsum",
    "3.1415926535897932...",
    "factorial(idea)",
    "A te convien tenere altro vïaggio",

];